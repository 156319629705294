import { TranslateResult } from "vue-i18n";
import { User, Role } from "@/types/user";
export default class ModelUser implements User {
  id!: string;
  uid!: string;
  username: string = "";
  firstName: string = "";
  lastName: string = "";
  enabled: boolean = false;
  emailVerified: boolean = false;
  email: string = "";
  institution: string = "";
  mobile: string = "";
  phone: string = "";
  description: string = "";
  roles: Role[] = [];
  stateChangeReason?: string = "";
  language: any = {
    label: "Deutsch",
    value: "de"
  };
  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
  get getLocale(): string {
    return `${this.language}-${this.language.toUpperCase()}`;
  }
}
export interface ModelUserRole {
  label: TranslateResult;
  id: number;
}

interface Lang {
  label: string;
  value: string;
}
