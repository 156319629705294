import cssVars from "css-vars-ponyfill";
// import "core-js";
import { Component } from "vue-property-decorator";

Component.registerHooks(["validations"]);
import "core-js/es/array";
import "core-js/es/date";
import "core-js/es/function";
import "core-js/modules/es.object.assign";
import "core-js/modules/es.array.iterator";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.set";
import "core-js/modules/es.reflect.apply";
import "core-js/modules/es.object.from-entries";

import Vue from "vue";
import Vuex, { Store } from "vuex";
import BootstrapVue from "bootstrap-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import { AppState, createStore } from "@/stores";
import Vuelidate from "vuelidate";
import i18n from "./i18n";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { keycloakConfig } from "./shared/keycloak";
import VueMq from "vue-mq";

import { SystemSettings } from "@/services/SystemSettings";
import { VueKeycloakInstance } from "@dsb-norge/vue-keycloak-js/dist/types";

// this part resolve an issue where the markers would not appear
// @ts-ignore
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
const currentUserIsAdminOrProjectManager = (): boolean => {
  return (
    (router as any).app.$keycloak.hasRealmRole("admin") || (router as any).app.$keycloak.hasRealmRole("project_manager")
  );
};
export const hasMaintenanceAccessRight = () =>
  (router as any).app.$keycloak.resourceAccess[process.env.VUE_APP_AUTHORIZATION_CLIENT_ID].roles.includes(
    "maintenance-access"
  );
const currentUserIsAdmin = () => (router as any).app.$keycloak.hasRealmRole("admin");
cssVars();

Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);

/*----------  Custom Breakpoints  ----------*/
Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: Infinity
  }
});
/*----------End  Custom Breakpoints  ----------*/
const store: Store<AppState> = createStore();

router.beforeEach(async (to, from, next) => {
  const systemConfig = await SystemSettings.getSystemConfig();
  const isInMaintenanceMode = systemConfig.status === 202 && systemConfig.data.maintenanceMode;
  store.dispatch("Theme/UPDATE_SYSTEM_STATE", isInMaintenanceMode);

  const productiveSystem = systemConfig.status === 202 && systemConfig.data.productiveSystem;
  store.dispatch("Theme/UPDATE_PRODUCTIVE_SYSTEM", productiveSystem);

  if (to.matched.some((record) => record.meta.requiresAuth) || to.matched.some((record) => record.meta.onlyAdmin)) {
    if (router.app.$keycloak.authenticated) {
      if (to.matched.some((record) => record.meta.onlyAdmin)) {
        if (currentUserIsAdminOrProjectManager()) {
          next();
        } else {
          next({ path: "/projects" });
        }
      } else {
        next({ path: "/projects" });
      }
    } else {
      const loginUrl = router.app.$keycloak.createLoginUrl?.() || "";
      window.location.replace(loginUrl);
    }
  } else if (to.matched.some((record) => record.meta.maintenanceModeCheck) && !hasMaintenanceAccessRight()) {
    if (isInMaintenanceMode) {
      next({ path: "/maintenance" });
    } else {
      next();
    }
  } else {
    next();
  }
});

Vue.use(VueKeyCloak, {
  init: {
    onLoad: "login-required",
    checkLoginIframe: false // Cancel the reload of the browser
  },
  config: keycloakConfig,
  onReady: async (keycloak: any) => {
    const maintenanceMode = await axios(`${process.env.VUE_APP_AUTHORIZATION}/api/v1/sysconfig`, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        Accept: "application/json"
      }
    });

    new Vue({
      el: "#app",
      router,
      store,
      template: "<App/>",
      i18n,

      components: {
        App
      }
    });
    const isInMaintenanceMode = maintenanceMode.status === 202 && maintenanceMode.data.maintenanceMode;
    store.dispatch("Theme/UPDATE_SYSTEM_STATE", isInMaintenanceMode);

    if (isInMaintenanceMode && !currentUserIsAdmin()) {
      // Go to maintenance page when the maintenance mode is on
      console.log("maintenance is on ");
      router.push("maintenance");
    }
  }
});

declare module "vue/types/vue" {
  // Declare augmentation for Vue
  interface Vue {
    $keycloak: VueKeycloakInstance;
  }
}
