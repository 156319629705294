export default class UserAccess {
  Access = {
    VIEW: {
      dashboard: {
        all: false,
        metrics: false,
        mainboardLinks: false
      },
      project: {
        leaderInformation: false,
        metadata: true,
        userList: false,
        sectionList: false,
        batteryList: false,
        callBatteryGPS: false,
        addBattery: false,
        deleteBattery: false,
        deviceList: true,
        profileList: true,
        report: {
          generateRadarDataReport: false,
          generateProjectReport: false,
          generateProfileMatrixReport: false
        },
        sectionListTab: {
          allTableHeaders: false,
          actions: false
        },
        informationModal: {
          info: true,
          settings: false,
          rawData: false,
          viewDesiredVsActualState: false,
          resetTrafficCount: false,
          administration: false,
        },
        cwuProject: {
          threshold: false
        },
        vsu: {
          profileListTab: {
            profilesTable: true,
            addNewProfile: false,
            modalAddProfile: false
          }
        },
        rzpProject: {
          emptyScene: false
        },
        vwa: {
          info: true,
          settings: false,
          viewDesiredVsActualState: false,
          resetTrafficCount: false,
          administration: false,
        }
      },
      projectList: {
        all: false,
        report: false,
        newProject: false,
        updateProject: false,
        actions: {
          view: false,
          edit: false,
          archive: false,
          toggle: false
        }
      },
      archiveProjectList: {
        all: false
      },
      profile: {
        all: true
      },
      schematicView: {
        sectionTitle: false,
        radarType: {
          averageRadarValue: false
        }
      }
    },
    EDIT: {
      projectList: {
        createProject: false
      },
      projectListArchived: {
        delete: false,
        archive: false
      },
      profile: {
        updateInformation: true,
        resetPassword: true
      },
      project: {
        editProject: false,
        activateSendAlertEmail: false,
        sectionListTab: {
          reRange: false,
          deleteSection: false,
          editSection: false,
          createSection: false,
          visibleSection: false
        },
        informationModal: {
          settings: false,
          changeProfile: false,
          administration: false
        },
        cwuProject: {
          threshold: {
            editAll: false
          }
        },
        sceneProject: {
          schematic: {
            switchScene: false
          }
        },
        vsu: {
          profileListTab: {
            editProfile: false,
            removeProfile: false
          }
        }
      },
      maintenanceMode: {
        edit: false
      },
      usersList: {
        createUser: false,
        createOnlyUser: false,
        actions: {
          delete: false,
          changeRole: false,
          editUser: false,
          resetCredentials: false,
          allActions: false
        }
      },
      inventory: {
        actions: {
          updateAll: false,
          createDevice: false
        },
        archivedList: {
          deleteDevice: false
        }
      },
      userRoles: {
        delete: false,
        create: false
      }
    }
  };

  public setAgencyAccess() {
    this.Access.VIEW.dashboard.all = true;
    this.Access.VIEW.project.informationModal.settings = true;
    this.Access.EDIT.project.sceneProject.schematic.switchScene = true;
  }

  public setConstructionManagerAccess() {
    this.Access.VIEW.dashboard.all = true;
    this.Access.VIEW.project.informationModal.settings = true;
    this.Access.EDIT.project.sceneProject.schematic.switchScene = true;
    this.Access.VIEW.projectList.actions.view = true;
    this.Access.VIEW.schematicView.sectionTitle = true;
    this.Access.VIEW.project.batteryList = true;
  }

  public setProjectManagerAccess() {
    this.Access.VIEW.project.sectionList = true;
    this.Access.VIEW.project.batteryList = true;
    this.Access.VIEW.project.callBatteryGPS = true;
    this.Access.VIEW.project.addBattery = true;
    this.Access.VIEW.project.deleteBattery = true;
    this.Access.VIEW.dashboard.metrics = true;

    this.Access.VIEW.project.leaderInformation = true;
    this.Access.VIEW.project.userList = true;
    this.Access.VIEW.project.cwuProject.threshold = true;
    this.Access.VIEW.project.rzpProject.emptyScene = true;

    this.Access.VIEW.project.report.generateProjectReport = true;
    this.Access.VIEW.project.report.generateRadarDataReport = true;
    this.Access.VIEW.project.report.generateProfileMatrixReport = true;
    this.Access.VIEW.project.informationModal.viewDesiredVsActualState = true;
    this.Access.VIEW.project.informationModal.administration = true;

    this.Access.VIEW.schematicView.sectionTitle = true;

    this.Access.VIEW.project.vsu.profileListTab.addNewProfile = true;
    this.Access.VIEW.project.vsu.profileListTab.modalAddProfile = true;

    this.Access.VIEW.project.sectionListTab.allTableHeaders = true;
    this.Access.VIEW.project.sectionListTab.actions = true;

    this.Access.EDIT.project.sectionListTab.reRange = true;
    this.Access.EDIT.project.sectionListTab.editSection = true;
    this.Access.EDIT.project.sectionListTab.deleteSection = true;
    this.Access.EDIT.project.sectionListTab.createSection = true;
    this.Access.EDIT.project.sectionListTab.visibleSection = true;

    this.Access.EDIT.project.vsu.profileListTab.editProfile = true;

    this.Access.EDIT.project.editProject = true;
    this.Access.EDIT.project.informationModal.changeProfile = true;

    this.Access.VIEW.projectList.report = true;
    this.Access.VIEW.projectList.actions.view = true;
    this.Access.VIEW.projectList.actions.edit = true;
    this.Access.VIEW.projectList.actions.archive = true;
    this.Access.VIEW.projectList.actions.toggle = true;

    this.Access.VIEW.projectList.newProject = true;

    this.Access.EDIT.projectListArchived.archive = true;

    this.Access.EDIT.usersList.createUser = true;
    this.Access.EDIT.usersList.actions.editUser = true;
    this.Access.EDIT.usersList.actions.resetCredentials = true;
    this.Access.EDIT.usersList.createOnlyUser = false;
    this.Access.EDIT.usersList.actions.changeRole = true;

    this.Access.EDIT.project.vsu.profileListTab.removeProfile = true;

    this.Access.EDIT.project.sceneProject.schematic.switchScene = true;
  }

  public setAdminAccess() {
    this.Access.VIEW.dashboard.mainboardLinks = true;

    this.Access.VIEW.projectList.all = true;

    this.Access.VIEW.archiveProjectList.all = true;

    this.Access.VIEW.project.informationModal.rawData = true;
    this.Access.VIEW.project.informationModal.administration = true;
    this.Access.VIEW.project.informationModal.resetTrafficCount = true;

    this.Access.VIEW.schematicView.radarType.averageRadarValue = true;

    this.Access.VIEW.project.batteryList = true;
    this.Access.VIEW.project.callBatteryGPS = true;
    this.Access.VIEW.project.addBattery = true;
    this.Access.VIEW.project.deleteBattery = true;

    this.Access.EDIT.project.activateSendAlertEmail = true;
    this.Access.EDIT.project.cwuProject.threshold.editAll = true;
    this.Access.EDIT.projectListArchived.delete = true;

    this.Access.EDIT.maintenanceMode.edit = true;

    this.Access.EDIT.usersList.createOnlyUser = false;
    this.Access.EDIT.usersList.actions.allActions = true;
    this.Access.EDIT.usersList.actions.delete = true;
    this.Access.EDIT.usersList.actions.changeRole = true;

    this.Access.EDIT.inventory.actions.updateAll = true;
    this.Access.EDIT.inventory.actions.createDevice = true;
    this.Access.EDIT.inventory.archivedList.deleteDevice = true;

    this.Access.EDIT.userRoles.create = true;
    this.Access.EDIT.userRoles.delete = true;
  }

  get getUserAccess() {
    return this.Access;
  }
}
