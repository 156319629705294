export interface OverViewLabels {
  mainboardUid: string;
  connectionQuality: ConnectionQualityInterface | null;
  iccId: string;
  routerSerialNumber: string;
  gpsPosition: null | GpsPosition;
  position: string;
  version: string;
  lastSeen: string;
  ping: PingInterface | null;
}
interface ConnectionQualityInterface {
  signalLevelAsu: number;
  signalLevelDbm: number;
  rsrp: string;
  rsrq: string;
}
interface GpsPosition {
  latitude: number;
  longitude: number;
}

interface PingInterface {
  latencyMainboard: number;
  latencyRouter: number;
}

export interface MainboardAndRouterUrl {
  routerUrl: string | null;
  mainboardUrl: string | null;
}

export interface BatteryDevice {
  id: string;
  serialNumber: string;
  iccId: string;
  latitude: string;
  longitude: string;
  gpsLastUpdated: Date;
}

export enum DeviceState {
  NEW = "NEW",
  AVAILABLE = "AVAILABLE",
  MAINTENANCE = "MAINTENANCE",
  IN_FIELD = "IN_FIELD",
  BOOKED = "BOOKED",
  ARCHIVED = "ARCHIVED"
}
