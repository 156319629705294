import Project from "./Project";

export default class ArchiveProjectList {
  projectList: Array<Project> = [];

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }

  get allMainProjects(): Array<Project> {
    const mainProjectAndSpeedFunnel = this.projectList.filter(
      (item: Project) => item.type?.name === "main_project" || item.type?.name === "speedfunnel" || item.type?.name === "congestion_warning_cascaded"
    );
    const mainProject = mainProjectAndSpeedFunnel.filter(p => p.type?.name === "main_project");
    const speedFunnel = mainProjectAndSpeedFunnel
      .filter(p => p.type?.name === "speedfunnel" || p.type?.name === "congestion_warning_cascaded")
      .filter(p => {
        const mainProjectId = p.parent?.id;
        const haveParentInTheMainProjectList = mainProject.find(p => p.id === mainProjectId);
        return haveParentInTheMainProjectList ? null : p;
      })
      .filter(item => item !== null);

    return [...mainProject, ...speedFunnel];
  }
  get subProjects() {
    return (id: number) => this.projectList.filter((project: Project) => project.parent?.id === id);
  }
}
