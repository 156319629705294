import { ProjectProfile } from "@/interface/projectsInterface";
import { Reasons, DEVICE_STATUS } from "@/model/ModelSection";

export class DisplaySettings {
  id: number = 0;
  filename: string = "";
  filenameTop: string = "";
  filenameMid: string = "";
  filenameBottom: string = "";
  manualOverride: boolean = false;
}
export class measureAndCameraSettings {
  id: number = 0;
  outputOne = false;
  outputTwo = false;
  rollAngle = 0.0;
  pitchAngle = 0.0;
  angleThreshold1 = 5;
  angleThreshold2 = 10;

}
export class VsuDisplaySettings extends DisplaySettings {
  switchingProfile: ProjectProfile = new ProjectProfile();
  cbRadioEnabled: boolean = false;
  cbradioEnabled: boolean = false;
  prewarningEnabled: boolean = false;
}
export class RzuDisplaySettings extends DisplaySettings {
  brightness: number = 0;
  brightnessOverride: boolean = false;
}
export class SceneDisplaySettings extends DisplaySettings {}
export class FPUDisplaySettings extends DisplaySettings {
  brightness: number = 0;
  brightnessOverride: boolean = false;
  cbRadioEnabled: boolean = false;
  cbradioEnabled: boolean = false;
  prewarningEnabled: boolean = false;
  cleared: boolean = true;
}
export class CwuDisplaySettings extends DisplaySettings {
  cbRadioEnabled: boolean = false;
  prewarningEnabled: boolean = false;
  customFilenameEnabled: boolean = false;
  customFilenameTraffic: string = "";
  customFilenameTrafficRisk: string = "";
  customFilenameOff: string = "";
}

export class VWASettings extends DisplaySettings {
  cbRadioEnabled: boolean = false;
  prewarningEnabled: boolean = false;
  text: string = "";
  textSize: number = 0;
}

export interface SingleDeviceState {
  reasons: Array<Reasons>;
  radarData: Array<RadarData>;
  rawInfo: string;
  picture: string | null;
  status: DEVICE_STATUS;
  kilometrage?: string;
  mainboardUid: string;
  slaveboardUid: string;
  routerSerialNumber: string;
  lastSeen: string;
  version: string;
  iccId: string;
  width: string;
  height: string;
  brightnessAuto: boolean;
  brightness: string;
  provider: string;
  netType: string;
  signalLevelAsu: number;
  signalLevelDbm: number;
  rsrp: string;
  rsrq: string;
  latencyMainboard: number;
  latencyRouter: number;
  prewarningLamp: boolean;
  cbRadio: boolean;
  gpsPosition: null | {
    latitude: number;
    longitude: number;
  };
  routerUrl: string;
  mainboardUrl: string;
  supportEnabled: boolean;
  online: boolean;
  deviceBatteryState?: {
    batteryTerminalCurrent: string;
    batteryTerminalValue: number;
    batteryTerminalUnit: string;
    batteryStatus: string;
    chargingCurrent: string;
    dischargingCurrent: string;
    solarArray: string;
  };
  rollAngle: number;
  pitchAngle: number;
  rollAngleUnit: string;
}
export interface StateDetails {
  deviceStateOne: SingleDeviceState;
  deviceStateTwo: SingleDeviceState;
}
export class SectionStateDTO {
  sectionId: number = 0;
  state = new SectionState();
  constructor(stateDTO?: any) {
    Object.assign(this, stateDTO);
  }
}
interface PossiblesSetting {
  fpuSettings: FPUDisplaySettings;
  vsuSettings: VsuDisplaySettings;
  rzuSettings: RzuDisplaySettings;
  sceneSettings: SceneDisplaySettings;
  cwuSettings: CwuDisplaySettings;
}
export type SettingTypes = Extract<keyof SectionState, keyof PossiblesSetting>;
export default class SectionState {
  id: number | null = null;
  status: string = "ALERT";
  fpuSettings: FPUDisplaySettings = new FPUDisplaySettings();
  vsuSettings: VsuDisplaySettings = new VsuDisplaySettings();
  rzuSettings: RzuDisplaySettings = new RzuDisplaySettings();
  sceneSettings: SceneDisplaySettings = new SceneDisplaySettings();
  measureSettings: measureAndCameraSettings = new measureAndCameraSettings();
  cameraSettings: measureAndCameraSettings = new measureAndCameraSettings();
  cwuSettings: CwuDisplaySettings | null = new CwuDisplaySettings();
  vwaSettings: VWASettings | null = new VWASettings();
  reasons: Array<object> = [];
  stateDetails!: StateDetails;
  occupancyAverage: string = "";
  occupiedSpeedAverage: string = "";
  // stateDetails: stateDetails | null = null;

  worstLaneName = "";
  worstLaneOccupancy = "0 %";
  worstLaneSpeed = "";

  constructor(sectionStateIncoming?: any) {
    if (sectionStateIncoming) {
      Object.assign(this, sectionStateIncoming);
    }
  }

  public get haveRadarData(): boolean {
    if (this.stateDetails && this.stateDetails.deviceStateOne) {
      return !!this.stateDetails.deviceStateOne.radarData.length;
    } else {
      return false;
    }
  }

  public get allRadarData(): Array<any> {
    if (this.stateDetails.deviceStateOne) {
      return this.stateDetails.deviceStateOne.radarData;
    } else {
      return [];
    }
  }

  public get deviceOneHaveRawInfo(): boolean {
    return !!this.stateDetails?.deviceStateOne?.rawInfo;
  }
  public get deviceTwoHaveRawInfo(): boolean {
    return !!this.stateDetails?.deviceStateTwo?.rawInfo;
  }

  public get haveStateDeviceOne(): boolean {
    return !!this.stateDetails?.deviceStateOne;
  }

  public get haveStateDeviceTwo(): boolean {
    return !!this.stateDetails?.deviceStateTwo;
  }

  public getDesiredPictureName(setting: SettingTypes): string {
    return this[setting]?.filename || "noPicture.jpg";
  }
}

export interface RadarData {
  averageSpeed: string;
  occupancy: string;
  name: string;
}
