import { VolumeAnalysisVariant, ProjectStatus, Types } from "@/interface/projectsInterface";
import Section from "@/model/ModelSection";

import IbomadeUserService from "@/services/ibomadeUserService";
import i18n from "@/i18n";
import ModelUser from "./ModelUser";
import { SettingTypes } from "./ModelSectionState";

interface User {
  id: number;
  uid: string;
}

export default class Project {
  id: number = 0;
  name: string = "";
  dateStart: string = "";
  dateEnd: string = "";
  description: string = "";
  parent: Project | null = null;
  countryId: number = 0;
  federalStateId: number = 0;
  country?: {
    id: number;
    name: string;
    states: Array<any>;
  };
  federalState?: {
    id: number;
    name: string;
  };
  providence: string = "";
  directionNameStart: string = "";
  directionNameEnd: string = "";
  directionPositionStart: number | null = null;
  directionPositionEnd: number | null = null;
  status: ProjectStatus | null = null;
  leader: ModelUser | null = null;
  projectLeader: ModelUser = new ModelUser();
  addinfo: string = "";
  type: Types | null = null;
  sections: Section[] = [];
  users: Array<User> = [];
  switchingReport: boolean = false;
  trafficReport: boolean = false;
  switchingMatrixReport: boolean = false;
  alertEmail: boolean = true;
  controllerEmail: string = "";
  replaceBatteryEmailActive: boolean = true;
  volumeAnalysisVariant: VolumeAnalysisVariant | null = null;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }

  public updateStatus() {
    if (this.status) {
      this.status = {
        ...this.status,
        label: i18n.t(`project.statusOption.${this.status.name}`)
      };
    }

    if (typeof this.volumeAnalysisVariant === "string" || this.volumeAnalysisVariant === null) {
      this.volumeAnalysisVariant = {
        name: this.volumeAnalysisVariant || "",
        label: i18n.t(`project.volumeAnalysisVariantOptions.${this.volumeAnalysisVariant || "null"}`)
      };
    }
  }

  public async getLeaderInformation() {
    if (this.leader) {
      const leader = await IbomadeUserService.getUser(this.leader.uid);
      this.projectLeader = leader.data;
      this.leader = this.projectLeader;
    }
  }

  get isProjectActive(): boolean {
    if (this.status) {
      return this.status.name === "active";
    } else {
      return false;
    }
  }

  get isProjectPlaning(): boolean {
    if (this.status) {
      return this.status.name === "planning";
    } else {
      return false;
    }
  }
  get isProjectDismantling(): boolean {
    if (this.status) {
      return this.status.name === "dismantling";
    } else {
      return false;
    }
  }
  get isFPU(): boolean {
    if (this.type) {
      return this.type.name === "free_programmable";
    } else {
      return false;
    }
  }

  get isCWU(): boolean {
    if (this.type) {
      return this.type.name.startsWith("congestion_warning_aq_");
    } else {
      return false;
    }
  }

  get isVWA(): boolean {
    if (this.type) {
      return this.type.name.startsWith("vwa");
    } else {
      return false;
    }
  }

  get isVSU(): boolean {
    if (this.type) {
      return this.type.name === "variable_message_signs";
    } else {
      return false;
    }
  }

  get isRZP(): boolean {
    if (this.type) {
      return this.type.name === "roadzipper";
    } else {
      return false;
    }
  }

  get isSCENE(): boolean {
    if (this.type) {
      return this.type.name === "scene_based";
    } else {
      return false;
    }
  }

  get isMq3Aq3(): boolean {
    if (this.type) {
      const mq3Aq3 = ["congestion_warning_aq_5"];
      return mq3Aq3.includes(this.type.name);
    } else {
      return false;
    }
  }

  get isMq1Aq1HYST(): boolean {
    if (this.type) {
      const mq1Aq1HYST = ["congestion_warning_aq_6"];
      return mq1Aq1HYST.includes(this.type.name);
    } else {
      return false;
    }
  }

  get isMq3Aq32SW(): boolean {
    if (this.type) {
      const mq3Aq32SW = ["congestion_warning_aq_7"];
      return mq3Aq32SW.includes(this.type.name);
    } else {
      return false;
    }
  }

  get isMq4Aq42SWDEGES(): boolean {
    if (this.type) {
      const mq4Aq42SWDEGES = ["congestion_warning_aq_8"];
      return mq4Aq42SWDEGES.includes(this.type.name);
    } else {
      return false;
    }
  }
  get isMq3Aq32023(): boolean {
    if (this.type) {
      const mq3Aq32023 = ["congestion_warning_aq_9"];
      return mq3Aq32023.includes(this.type.name);
    } else {
      return false;
    }
  }
  get isMq4Aq42SW(): boolean {
    if (this.type) {
      const mq4Aq42SW = ["congestion_warning_aq_10"];
      return mq4Aq42SW.includes(this.type.name);
    } else {
      return false;
    }
  }
  get isMq5Aq52SW(): boolean {
    if (this.type) {
      const mq5Aq52SW = ["congestion_warning_aq_11"];
      return mq5Aq52SW.includes(this.type.name);
    } else {
      return false;
    }
  }
  get isCwuCascaded(): boolean {
    if (this.type) {
      const cwuCascaded = ["congestion_warning_cascaded"];
      return cwuCascaded.includes(this.type.name);
    } else {
      return false;
    }
  }
  get isMq1Aq12SW(): boolean {
    if (this.type) {
      const mq1Aq12SW = ["congestion_warning_aq_12"];
      return mq1Aq12SW.includes(this.type.name);
    } else {
      return false;
    }
  }

  get getFormattedStartAndEndInformation(): string {
    const formattedString: string = `${this.directionNameStart} (${this.directionPositionStart})KM - ${this.directionNameEnd} (${this.directionPositionEnd})KM `;
    return formattedString;
  }

  get isArchived(): boolean {
    const archive = this.status?.id === 4 || false;
    return archive;
  }

  get isFinish(): boolean {
    const finish = this.status?.id === 3 || false;
    return finish;
  }
  get typeSettingsName(): SettingTypes {
    const settingOptions: Record<number, SettingTypes> = {
      2: "fpuSettings",
      4: "cwuSettings",
      5: "cwuSettings",
      6: "cwuSettings",
      7: "cwuSettings",
      9: "cwuSettings",
      12: "cwuSettings",
      3: "vsuSettings",
      8: "rzuSettings",
      10: "sceneSettings"
    };
    const typeId = this.type?.id || 2;
    return settingOptions[typeId];
  }

  public get getAllUserInTheProject(): Array<string> {
    return this.users.map((user: any): string => user.uid);
  }

  public async getDetailsOfAllUsers() {
    const allUsersDetails = [];
    for (const item of this.getAllUserInTheProject) {
      const user = await IbomadeUserService.getUser(item);
      if (user.status === 200) {
        allUsersDetails.push(user.data);
      }
    }

    return allUsersDetails;
  }

  public formatToUpdate() {
    const {
      addinfo,
      country,
      federalState,
      dateEnd,
      dateStart,
      description,
      directionNameEnd,
      directionNameStart,
      directionPositionEnd,
      directionPositionStart,
      leader,
      name,
      parent,
      status,
      type,
      id,
      users,
      trafficReport,
      switchingReport,
      switchingMatrixReport,
      alertEmail,
      replaceBatteryEmailActive,
      controllerEmail,
      volumeAnalysisVariant
    } = this;

    return {
      addinfo,
      countryId: country?.id,
      dateEnd,
      dateStart,
      description,
      directionNameEnd,
      directionNameStart,
      directionPosEnd: directionPositionEnd,
      directionPosStart: directionPositionStart,
      federalStateId: federalState?.id,
      leaderId: leader ? leader.id : null,
      name,
      parentProjectId: parent?.id,
      projectStatusId: status?.id,
      projectTypeId: type?.id,
      users: users.map((item: any) => item.uid),
      id,
      switchingReport,
      trafficReport,
      switchingMatrixReport,
      alertEmail,
      replaceBatteryEmailActive,
      controllerEmail,
      volumeAnalysisVariant: volumeAnalysisVariant?.name ? volumeAnalysisVariant?.name : null
    };
  }
}

export class TrafficCountStats {
  sectionId = 0;
  from = Date.now();
  to = Date.now();
  trafficClassLabels: { [key: string]: any } = {};
  occupancyByClass: { [key: string]: any } = {};
  volumeByClass: { [key: string]: any } = {};
  avgSpeedByClass: { [key: string]: any } = {};
  totalVolume: { [key: string]: any } = {};
  currentVolumeByClass: { [key: string]: any } = {};
  totalCurrentVolumeByClass: { [key: string]: any } = {};
  removeFromTotal: { [key: string]: any } = {};
  totalVolumePerHour: any = 0;
  carSimilarVolumePerHour: any = 0;
  truckSimilarVolumePerHour: any = 0;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }

  public get formattedData() {
    const {
      sectionId,
      from,
      to,
      totalVolume,
      currentVolumeByClass,
      volumeByClass,
      avgSpeedByClass,
      trafficClassLabels,
      occupancyByClass,
      totalVolumePerHour,
      carSimilarVolumePerHour,
      truckSimilarVolumePerHour
    } = this;
    return {
      sectionId,
      from,
      to,
      trafficClassLabels,
      volumeByClass: this.RemoveDummyClass(volumeByClass, true),
      occupancyByClass: this.RemoveDummyClass(occupancyByClass),
      avgSpeedByClass: this.RemoveDummyClass(avgSpeedByClass),
      currentVolumeByClass: this.RemoveDummyClass(currentVolumeByClass),
      totalVolume: this.formatTotalVolume(totalVolume),
      totalCurrentVolumeByClass: this.formatTotalCurrent(this.RemoveDummyClass(currentVolumeByClass)),
      totalVolumePerHour,
      carSimilarVolumePerHour,
      truckSimilarVolumePerHour
    };
  }

  private RemoveDummyClass(item: { [key: string]: any }, isVolume = false) {
    const newItem: { [key: string]: any } = {};
    for (const line in item) {
      if (Object.prototype.hasOwnProperty.call(item, line)) {
        newItem[line] = {
          3: item[line][3],
          4: item[line][4],
          6: item[line][6],
          7: item[line][7],
          8: item[line][8]
        };
        if (isVolume) {
          this.removeFromTotal[line] = item[line][1] + item[line][2];
        }

        // delete item[line][0];
        // delete item[line][1];
        // delete item[line][2];
        // delete item[line][5];
      }
    }
    return newItem;
  }

  private formatTotalVolume(total: { [key: string]: any }) {
    const newTotal = { ...total };
    for (const key in total) {
      if (Object.prototype.hasOwnProperty.call(total, key)) {
        newTotal[key] = total[key] - this.removeFromTotal[key];
      }
    }
    return newTotal;
  }

  private formatTotalCurrent(total: { [key: string]: object }) {
    const newTotal: { [key: string]: number } = {};
    for (const key in total) {
      if (Object.prototype.hasOwnProperty.call(total, key)) {
        const lineSum: number = Object.values(total[key]).reduce(
          (accumulator: number, currentValue: number): number => {
            return accumulator + currentValue;
          },
          0
        );
        newTotal[key] = lineSum;
      }
    }
    return newTotal;
  }
}

export class ProjectThreshold {
  id: number = 0;
  stateId: number = 0;
  typeId: number = 0;
  high: number = 0;
  low: number = 0;
  mid: number = 0;
  observationPeriod: number = 0;
  occupancyLimit: number = 0;
  occupancyFactor: number = 0;

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
